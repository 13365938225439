import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

export default function Chart() {
    
  
  
    function createMarkup() {
      const id = "0x9d4c1d37E78A46A991854D6A71F1EEdC9f349150"; // useParams();
        return {__html: `<iframe width="100%" height="1000" frameBorder="0" scrolling="no" src="https://coinbrain.com/embed/bnb-${id}?theme=custom&padding=16&background=100c18&chart=1&trades=1"></iframe>`};
      }
      
      function MyChart() {
        return <div style={{width: "100%"}} dangerouslySetInnerHTML={createMarkup()} />;
      }

    return (
        MyChart()
    )

}